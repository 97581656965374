import React from 'react';
import { Button, Col, Descriptions, Row, Typography } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Metadata } from '../components';
import StandardLayout from '../layouts/standard';

const WorkDetailsTemplate = ( { pageContext } ) => {
    const { name, description, price, data_item_file_guid, code, work } = pageContext
    return (
      <StandardLayout>
        <Metadata title={ `${ work.title }: ${ name }` } />
        <Row>
          <Col span={ 24 }>
            <Typography.Title level={ 2 }>{ `${ work.title }: ${ name }` }</Typography.Title>
            <Descriptions>
              <Descriptions.Item label="Price">{ price }</Descriptions.Item>
            </Descriptions>
            <Typography.Text>{ description }</Typography.Text>            
          </Col>
        </Row>
        <Row>
          <Col span={ 24 }>
            <Button 
              type="text" 
              className="snipcart-add-item" 
              data-item-id={ code }
              data-item-price={ price }
              data-item-url={ `/works/${ work.code }/${ code }` }
              data-item-description={ description }
              data-item-file-guid={ data_item_file_guid }
              data-item-name={ name }
              icon={ <ShoppingCartOutlined /> }
            >
              Buy
            </Button>
          </Col>
        </Row>
      </StandardLayout>
    )
}

export default WorkDetailsTemplate;